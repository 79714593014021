import React from "react";
import Modal from 'react-bootstrap/Modal'
import Spinner from 'react-bootstrap/Spinner'
import Button from 'react-bootstrap/Button'

class MyModal extends React.Component{


    render(){
        let {title, body, showSpinner, show, handleClose, backdrop,keyboard,showFooter, showHeader,scrollable,
          size,fullscreen} = this.props;
        return(
<>
<Modal
        show={show}
        onHide={handleClose}
        backdrop={backdrop}
        keyboard={keyboard}
        scrollable={scrollable}
        size={size}
        fullscreen={fullscreen}
      >
          {(showHeader)?
          <Modal.Header closeButton>
          <Modal.Title>{title}</Modal.Title>
        </Modal.Header>:""
        }
        <Modal.Body>
          
            {(showSpinner)?<div className="text-center">
          <Spinner animation="border" role="status">
          <span className="visually-hidden">Loading...</span>
        </Spinner></div>
        :""}
        
        <p className="text-center">{body}</p>
          
        </Modal.Body>
        {(showFooter)?
            <Modal.Footer>
            <Button variant="secondary" onClick={handleClose}>
            Kapat
            </Button>
        </Modal.Footer>:""    
            }
        
      </Modal>
</>
        );
    }
}

export default MyModal;