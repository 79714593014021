import React from 'react';
import Select from 'react-select'
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import Alert from 'react-bootstrap/Alert'
import ReCAPTCHA from "react-google-recaptcha";
import MyModal from '../../components/MyModal';
import { webserviceUrl, isValidEmail } from '../../consants';
import AsyncSelect from 'react-select/async';
import axios from 'axios';
import PhoneInput from 'react-phone-input-2'
import 'react-phone-input-2/lib/style.css'
import CurrencyInput from 'react-currency-input-field';


const GenderOptions = [
  {value: '0',label:"Belirtilmedi"},
  {value: '1',label:"Kadın"},
  {value: '2',label:"Erkek"}
]

const EducationOptions = [
  {value: '1',label:"İlk Öğretim"},
  {value: '2',label:"Orta Öğretim"},
  {value: '3',label:"Lise"},
  {value: '4',label:"Üniversite"},
  {value: '5',label:"Yüksek Lisans"},
  {value: '6',label:"Doktora"}
]

const InvestmentBudgetOptions = [
  {value: '1',label:"150.000 TL - 300.000 TL"},
  {value: '2',label:"300.000 TL - 500.000 TL"},
  {value: '3',label:"500.000 TL - 600.000 TL"},
  {value: '4',label:"600.000 TL - 700.000 TL"},
  {value: '5',label:"700.000 TL Üzeri"}
]

const SourceOfInvestmentOptions = [
  {value: '1',label:"Öz Sermaye"},
  {value: '2',label:"Banka Kredisi"},
  {value: '3',label:"Diğer"},
]

class Franchising extends React.Component{
  constructor(props){
    super(props);
    this.state = {
      adi:"",soyadi:"",dogumTarihi:"",dogumYeri:"",cinsiyet:"",meslek:"",eposta:"",tel:"",butce:0,isbilgisi:false,
      isbilgisi_vergiDairesi:"",isbilgisi_vergiNo:"",referanslar:"",konu:"",adres:"",yatirimnedeni:"",
      yatirimsehirler:[],yatirimyurtdisi:false,yatirimsehirleryurtdisi:[{country:"",city:""}],
      gayrimenkulVarmi:"",gayrimenkulDurumu:"",yatirimKaynagi:"",aylikKarBeklentisi:0,notlar:"",
      g_recaptcha_response:"",kvkkonayli:false,
      citiesDefaultOptions:[],
      dogumyeriYurtdisi:false,dogumyeriUlke:"",dogumyeriSehir:"",
      isEmailValid:true,
      egitim:""
    }
  }

  componentDidMount(){
    this.setCitiesDefaultOptions();
  }

  handleShow_Kvkk = () =>{
    this.setState({showKvkk:true})
  }
  handleClose_Kvkk = () =>{
    this.setState({showKvkk:false});
   }
  basvuruyuTamamla = async () =>{

    let present = this;
    
    await axios.post(webserviceUrl+"service/franchise/basvuruyap",this.state).then(response =>{
      alert("Başvurunuz alınmıştır. Teşekkür ederiz")
     window.parent.location.reload();
    }).catch((error)=>{
      alert("Bir sorun oluştu! Lütfen daha sonra tekrar deneyin")
    });
    
  }

  yurtdisiSehirEkle = ()=>{
    const sehirler = this.state.yatirimsehirleryurtdisi;
    let lastCountry = "";
    if(sehirler.length > 0) lastCountry = sehirler[sehirler.length -1].country;
    sehirler.push({country:lastCountry,city:""});
    this.setState({yatirimsehirleryurtdisi:sehirler});
  }

  setCitiesDefaultOptions = async () =>{
    let present = this;
    await axios.post(webserviceUrl+"service/lib/sehirler",{"query":""}).then(response =>{
      var c = [];
      response.data.forEach(element => {
        c.push({value: element.id,label:element.adi})
      });
      present.setState({citiesDefaultOptions:c})
    }).catch((error)=>{
      console.log(error)
    });
  }

  cities = async (value,callback) => {
    await axios.post(webserviceUrl+"service/lib/sehirler",{"query":value}).then(response =>{
      var c = [];
      response.data.forEach(element => {
        c.push({value: element.id,label:element.adi})
      });
      callback(c);
    }).catch((error)=>{
      console.log(error)
    });
  };

  yatirimSehirSil = (i) =>{
    
      const list = this.state.yatirimsehirleryurtdisi;
      list.splice(i, 1);
      this.setState({yatirimsehirleryurtdisi:list});
    }
  

  render(){

    let {adi,soyadi,dogumTarihi,isbilgisi,isbilgisi_vergiDairesi,isbilgisi_vergiNo,referanslar,konu,adres,yatirimnedeni,yatirimyurtdisi,
      gayrimenkulVarmi,notlar,showKvkk,citiesDefaultOptions,dogumyeriYurtdisi,dogumyeriUlke,dogumyeriSehir,isEmailValid,
    tel} = this.state;
    let present = this;
    return(
      <div className='bg-colored'>
      <div className='container'>

          <MyModal 
          title="KVKK AYDINLATMA METNİ"
          body={<iframe title='KVKK' src={webserviceUrl+"/service/kvkk"} style={{width:"100%", height:"420px"}} />}
          showSpinner={false}
          show={showKvkk}
          handleClose={this.handleClose_Kvkk}
          backdrop="false"
          keyboard={false}
          showFooter={true}
          showHeader={true}
          scrollable={true}
          size={"xl"}
          fullscreen={"xxl-down"}
        ></MyModal>

        <div className='card'>
          <div className='card-header'>
          <h5 className="mb-3 mt-3">KİŞİSEL BİLGİLER / PERSONEL INFORMATION</h5>
          </div>  
          <div className='card-body'>
          <div className="row g-3">
            <div className="col-sm-6">
                <label htmlFor="txtAdi" className="form-label">Adınız / Name</label>
                <input type="text" className="form-control" id="txtAdi" placeholder="" 
                onChange={e=>this.setState({adi:e.target.value})} value={adi} required autoComplete="off" />
              </div>
              <div className="col-sm-6">
                <label htmlFor="txtSoyadi" className="form-label">Soyadınız / Surname</label>
                <input type="text" className="form-control" id="txtSoyadi" placeholder="" onChange={e=>this.setState({soyadi:e.target.value})} value={soyadi} required autoComplete="off" />
            </div>
          </div>

          <div className="row g-3 mt-1">
              <div className="col-sm-6">
                    <label htmlFor="selectPlaceOfBirth" className="form-label">Doğum Yeri / Place of Birth</label>
                      <AsyncSelect isDisabled={dogumyeriYurtdisi} loadOptions={this.cities} defaultOptions={citiesDefaultOptions} placeholder="Seçin..." id="selectPlaceOfBirth" onChange={e=>this.setState({dogumYeri:e.value})}/>
                      <div className="row g-3 mt-1">
                      <div className="col-sm-12">
                        <input className="form-check-input" type="checkbox" onChange={e=>this.setState({dogumyeriYurtdisi:e.target.checked})} id="chdogumyeriyurtdisi" checked={dogumyeriYurtdisi}/>
                        &nbsp; <label className="form-check-label" htmlFor="chdogumyeriyurtdisi">Türkiye dışında doğdum / I was born outside Turkey</label>
                      </div>
                      {(dogumyeriYurtdisi) ?
                      <div className='col-sm-12'>
                          <div className="row g-3 mt-1">
                          <div className="col-sm-6">
                            <label htmlFor="txtDogumyeriUlke" className="form-label">Doğduğunuz ülke / Country of birth</label>
                            <input type="text" className="form-control" id="txtDogumyeriUlke" placeholder="" onChange={e=>this.setState({dogumyeriUlke:e.target.value})} value={dogumyeriUlke} required autoComplete="off" />
                           </div>
                          <div className="col-sm-6">
                            <label htmlFor="txtDogumyeriSehir" className="form-label">Doğduğunuz şehir / Country of city</label>
                            <input type="text" className="form-control" id="txtDogumyeriSehir" placeholder="" onChange={e=>this.setState({dogumyeriSehir:e.target.value})} value={dogumyeriSehir} required autoComplete="off" />
                             </div>
                          </div>
                      </div> : null
                      }
                    </div>
               </div>

              <div className="col-sm-6">
                      <label htmlFor="txtDogumTarihi" className="form-label">Doğum Tarihiniz / Date of Birth</label>
                        <DatePicker
                        id="txtDogumTarihi"
                        selected={dogumTarihi}
                        onSelect={date => this.setState({dogumTarihi:date})}
                        onChange={date => this.setState({dogumTarihi:date})}
                        dateFormat="dd/MM/yyyy"
                        className="form-control"
                        />
                </div>
            </div>

            <div className="row g-3 mt-1">
              <div className="col-sm-6">
                    <label htmlFor="selectGender" className="form-label">Cinsiyet / Gender</label>
                    <Select options={GenderOptions} placeholder="Seçin..." id="selectGender" onChange={e=>this.setState({cinsiyet:e.value})}/>
              </div>
              <div className="col-sm-6">
                    <label htmlFor="selectEducation" className="form-label">Eğitim / Education</label>
                    <Select options={EducationOptions} placeholder="Seçin..." id="selectEducation" onChange={e=>this.setState({egitim:e.value})}/>
              </div>
            </div>

            <div className="row g-3 mt-1">
              <div className="col-sm-6">
              <label htmlFor="txtMeslek" className="form-label">Meslek / Job</label>
                <input type="text" className="form-control" id="txtMeslek" placeholder="" onChange={e=>this.setState({meslek:e.target.value})} value={this.state.meslek} autoComplete="off" />
                </div>

                <div className="col-sm-6">
                <label htmlFor="txtEposta" className="form-label">E-posta / E-Mail</label>
                <input type="text" className="form-control" id="txtEposta" placeholder="" onChange={e=>{
                  let isvalid = isValidEmail(e.target.value);
                  present.setState({eposta:e.target.value,isEmailValid:isvalid});
                }} value={this.state.eposta} autoComplete="off" />
                {(!isEmailValid) ?
                  <div  className="alert alert-warning" role="alert">
                   Lütfen geçerli bir e-posta giriniz
                  </div>:null}
                </div>              
            </div>

            <div className="row g-3 mt-1">
              <div className="col-sm-6">
              <label htmlFor="txtTel" className="form-label">Telefon / Phone</label>
              <PhoneInput
                  country={'tr'}
                  value={tel}
                  onChange={value => this.setState({ tel:value })}
                />
                </div>
                         
            </div>

            <div className="row g-3 mt-1">
              <div className="col-sm-6">
                  <input className="form-check-input" type="checkbox" onChange={e=>this.setState({isbilgisi:e.target.checked})} id="chisbilgisi" checked={isbilgisi}/>
                  &nbsp; <label className="form-check-label" htmlFor="chisbilgisi">Sahibi veya ortağı olduğum bir şirket/firma var</label>
              </div>
            </div>
            {(isbilgisi)?<>
              <div className="row g-3 mt-1">
              <div className="col-sm-6">
              <label htmlFor="txtIsbilgisiVergiDairesi" className="form-label">Vergi Dairesi / Tax Office</label>
              <input type="text" className="form-control" id="txtIsbilgisiVergiDairesi" placeholder="" onChange={e=>this.setState({isbilgisi_vergiDairesi:e.target.value})} value={isbilgisi_vergiDairesi} autoComplete="off" />
              </div>
              <div className="col-sm-6">
              <label htmlFor="txtIsbilgisiVergiNo" className="form-label">Vergi No / Tax Identification Number</label>
              <input type="text" className="form-control" id="txtIsbilgisiVergiNo" placeholder="" onChange={e=>this.setState({isbilgisi_vergiNo:e.target.value})} value={isbilgisi_vergiNo} autoComplete="off" />
              </div>
            </div>
            </>:null}

            <div className="row g-3 mt-1">
              <label className="form-label" htmlFor="txtReferences">Referanslarınız / References</label>
                <textarea className="form-control" id="txtReferences" rows={3} autoComplete="off" onChange={e=>this.setState({referanslar:e.target.value})} value={referanslar} ></textarea>
            </div>

            </div>
      </div>
      <br />

      <div className='card'>
             
            <div className='card-header'>
            <h5 className="mb-3 mt-3">FRANCHISE</h5>
            </div>  
            <div className='card-body'>
            <div className="row g-3 mt-1">
            <div className="col-sm-6">
            <label className="form-label" htmlFor="txtKonu">İş Konusu / Business Issue</label>
            <input type="text" className="form-control" id="txtKonu" placeholder="" onChange={e=>this.setState({konu:e.target.value})} value={konu} autoComplete="off" />
            </div>
            <div className="col-sm-6">
                <label htmlFor="selectInvestmentBudget" className="form-label">Beta Tea House Yatırım Bütçeniz / Investment Budget</label>
                    <Select isSearchable={false} options={InvestmentBudgetOptions} placeholder="Seçin..." id="selectInvestmentBudget" onChange={e=>this.setState({butce:e.value})}/>
                   </div> 
            </div>

            <div className="row g-3 mt-1">
              <label className="form-label" htmlFor="txtAddress">Adres Bilgisi / Address</label>
              <div className="col-sm-12">
                <textarea className="form-control" id="txtAddress" rows={3} autoComplete="off" onChange={e=>this.setState({adres:e.target.value})} value={adres} ></textarea>
            </div></div>

            <div className="row g-3 mt-1">
              <label className="form-label" htmlFor="txtInvestmentReason">Beta Tea House Yatırımcısı Olma Nedeniniz? / Why do you want to become a BETA TEA HOUSE investor?</label>
              <div className="col-sm-12">
                <textarea className="form-control" id="txtInvestmentReason" rows={3} autoComplete="off" onChange={e=>this.setState({yatirimnedeni:e.target.value})} value={yatirimnedeni} ></textarea>
            </div></div>

            <div className="row g-3 mt-1">
            <label className="form-label" htmlFor="investmentCitiesOfTurkey">Türkiye İçi Hangi İllerde Açmayı Düşünüyorsunuz? / Which Cities Are You Considering in Turkey?</label>
            <AsyncSelect  loadOptions={this.cities} cacheOptions defaultOptions={citiesDefaultOptions}  isMulti={true} placeholder="Yazın..." id="investmentCitiesOfTurkey" onChange={e=>this.setState({yatirimsehirler:e.value})}/>
            </div>

            <div className="row g-3 mt-1">
            <div className="col-sm-6">
                  <input className="form-check-input" type="checkbox" onChange={e=>this.setState({yatirimyurtdisi:e.target.checked})} id="chinvestmentCitiesAbroad" checked={yatirimyurtdisi}/>
                  &nbsp; <label className="form-check-label" htmlFor="chinvestmentCitiesAbroad">Türkiye dışında açmak istiyorum / Outside Turkey</label>
              </div>
            </div>

            {(yatirimyurtdisi) ? 
               <>{
                this.state.yatirimsehirleryurtdisi.map(function(object,i){
                  return <div className="row g-3 mt-1" key={"abroadlist-"+i}>
                    <div className="col-lg-6">
                    <div className="row">
                      <div className="col-sm-6">
                        <label className="form-label" htmlFor={"txtCountry-"+i}>Ülke / Country</label>
                        <input type="text" className="form-control" id={"txtCountry-"+i} placeholder="" autoComplete="off" onChange={e=>{
                          let list = present.state.yatirimsehirleryurtdisi;
                          object.country = e.target.value;
                          list[i] = object;
                          present.setState({yatirimsehirleryurtdisi:list});
                        }} value={object.country} />
                      </div>
                      <div className="col-sm-5">
                        <label className="form-label" htmlFor={"txtcity-"+i}>Şehir / City</label>
                        <input type="text" className="form-control" id={"txtcity-"+i} placeholder="" autoComplete="off" value={object.city} onChange={e=>{
                          let list = present.state.yatirimsehirleryurtdisi;
                          object.city = e.target.value;
                          list[i] = object;
                          present.setState({yatirimsehirleryurtdisi:list});
                        }} />
                      </div>
                      <div className='col-sm-1' style={{padding:'10px'}}><button type="button" className="btn btn-sm btn-danger mt-4" onClick={()=>present.yatirimSehirSil(i)}>Çıkar</button></div>
                      </div>
                    </div>
                  </div>
                })
               }
                <button type="button" className="btn btn-md btn-secondary mt-3" onClick={()=>this.yurtdisiSehirEkle()}>Ekle</button>
               </>
              :null}

          <div className="row g-3 mt-1">
          <label className="form-label" htmlFor="selectGayrimenkulVarmi">Beta Tea House yatırımı için gayri menkulünüz var mı? / Do you have real estate for Beta Tea House investment?</label>
            <div className="col-sm-6" style={{maxWidth:"180px"}}>
            <Select options={[{value:"0",label:"Hayır"},{value:"1",label:"Evet"}]} isSearchable={false} placeholder="Seçin..." id="selectGayrimenkulVarmi" onChange={e=>this.setState({gayrimenkulVarmi:e.value})}/>
            </div>
              {(gayrimenkulVarmi === "1") ? 
              <div className="col-sm-6" style={{maxWidth:"180px"}}>
                <Select options={[{value:"1",label:"Sahibiyim"},{value:"0",label:"Kiracıyım"}]} isSearchable={false} placeholder="Seçin..." id="selectGayrimenDurumu" onChange={e=>this.setState({gayrimenkulDurumu:e.value})}/>
                </div>
              :null}
          </div>

          <div className="row g-3 mt-1">
            <div className="col-sm-6">
            <label className="form-label" htmlFor="selectSourceOfInvestment">Yatırım Kaynağınız / Source of Investment</label>
            <Select options={SourceOfInvestmentOptions} isSearchable={false} placeholder="Seçin..." id="selectSourceOfInvestment" onChange={e=>this.setState({yatirimKaynagi:e.value})}/>
            </div>
          </div>

          <div className="row g-3 mt-1">
          <label className="form-label" htmlFor="selectMonthlyProfitExpectation">Beta Tea House Aylık Kar Beklentiniz Nedir? / What is your monthly profit expectation?</label>
          <div className="col-sm-6">
            <CurrencyInput
              id="selectMonthlyProfitExpectation"
              name="selectMonthlyProfitExpectation"
              placeholder=""
              className='form-control'
              decimalsLimit={2}
              onValueChange={(value, name) => this.setState({aylikKarBeklentisi:value})}
              autoComplete="off"
            />
          </div>
          </div>

          <div className="row g-3 mt-1">
          <div className="col-sm-12">
              <label className="form-label" htmlFor="txtNot">İlave etmek istedikleriniz / What you want to add additionally</label>
                <textarea className="form-control" id="txtNot" rows={3} autoComplete="off" onChange={e=>this.setState({notlar:e.target.value})} value={notlar} ></textarea>
            </div></div>

          </div>
          </div>
          <br />

          <div className="row g-3 mt-1">
        <Alert variant="info">
        <div className="form-check">
          <input className="form-check-input" type="checkbox" checked={this.state.kvkkonayli} onChange={e=>this.setState({kvkkonayli:e.target.checked})} id="flexCheckDefault" />
          <label className="form-check-label" htmlFor="flexCheckDefault">
          Beta Gıda tarafından belirtilen KVKK aydınlatma metnini okuyup anladığımı kabul, beyan ve taahhüt ederim.
          </label>
        </div>
          <Alert.Link href="#" onClick={this.handleShow_Kvkk}>KVKK aydınlatma metinini okumak için burayı tıklayın</Alert.Link>
        </Alert>
        </div>

        <div className="row g-3 mt-1">
        <div className="col text-center">
        <div style={{display:'inline-block'}}>
        <ReCAPTCHA
            ref={(r) => this.recaptcha = r}
            sitekey="6Lft_aUfAAAAABTAoyDpOm9BcwAsSn7JaR8HGueV"
            onChange={(value)=>this.setState({g_recaptcha_response:value})}
        />
        </div>
        </div>
        </div>

      <div className="col mb-5 mt-5 text-center">
      <button type="submit" className="btn btn-md btn-primary mt-3" onClick={this.basvuruyuTamamla}>Başvuruyu Tamamla</button>
      </div>
      
      <div className="row mt-5 text-center p-3"><p>BETA TEA</p></div>
      </div>
      </div>
    )
  }
}


export default Franchising;